<script>
import { SimpleBar } from "simplebar-vue3";
import { GoogleMap, Marker } from "vue3-google-map";

import Layout from "../../layouts/main";
import appConfig from "@/app.config";

import { chatData, chatMessagesData } from "./data";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import axios from "axios";

/**
 * Chat Component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    SimpleBar,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      messages: [],
      support: {},
      file: null,
      doc: null,
      location: false,
      currentUser: null,
      markerOptions: {
        position: { lat: 24.774265, lng: 46.738586 },
        gmpDraggable: true,
      },
      center: { lat: 24.774265, lng: 46.738586 },
      chatData: chatData,
      chatMessagesData: chatMessagesData,
      title: "Chat",
      senderUser: {},
      items: [
        {
          text: "Skote",
          href: "/",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "فريق ملم",
      username_en: "Mulim Team",
    };
  },
  validations: {
    form: {
      message: {
        required: helpers.withMessage("Message is required", required),
      },
    },
  },
  computed: {
    getReadedMessage() {
      let items = this.messages
        .filter((item) => {
          if (item?.sender !== this.currentUser?.id && !item?.is_read) {
            console.log({ item });
            return item;
          }
        })
        ?.map((item) => item?.id);
      if (items?.length > 0) {
        this.socket.emit("readed_admin_message", items);
      }
      return items;
    },
  },
  methods: {
    async sendMedia(data) {
      try {
        const formData = new FormData();
        if (this.file) {
          formData.append("file", this.file);
          for (const key in data) {
            formData.append(key, data[key]);
          }
        } else {
          formData.append("file", this.doc);
          for (const key in data) {
            formData.append(key, data[key]);
          }
        }
        const response = await axios.post(
          `${this.$store?.state?.auth?.baseUrl}support-requests/add-chat-file`,
          formData
        );
        if (response?.data?.status) {
          this.getMessage();
          this.deleteFile();
          return;
        }
      } catch (err) {
        console.error(err);
        if (this.$i18n.locale == "ar") {
          this.responseAlert(
            "حدث خطأ اثناء الإرسال الرجاء المحاولة لاحقا",
            "خطأ",
            "warning"
          );
        } else {
          this.responseAlert(
            "something went wrong, please try again later",
            "error",
            "warning"
          );
        }
      }
    },
    async getMessage() {
      try {
        const response = await axios.post(
          `${this.$store?.state?.auth?.baseUrl}support-requests/${this.$route.params.id}`
        );
        if (response?.data?.data) {
          this.messages = response?.data?.data?.support_chats;
          this.senderUser = response?.data?.data?.user;
          this.messages.forEach((message) => {
            if (message?.lon && message?.lon) {
              message.imageUrl = `https://maps.googleapis.com/maps/api/staticmap?&markers=color:red%7Clabel:S%7C${message?.lat},${message?.lon}&center=${message?.lat},${message?.lon}&format=gif&zoom=14&size=400x400&key=AIzaSyChzOQofZVtnlA_g-ekWp5CLnUt2m7yUrw`;
            }
          });
          this.handleScroll();
        }
      } catch (err) {
        console.error(err);
      }
    },
    // async getLocationImage() {
    //   try {
    //     const response = await axios.get(`
    //     https://maps.googleapis.com/maps/api/staticmap?center=${message?.lat},${message?.lon}&format=gif&zoom=14&size=400x400&key=AIzaSyChzOQofZVtnlA_g-ekWp5CLnUt2m7yUrw`);
    //     console.log(response);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
    /**
     * Get the name of user
     */
    chatUsername(name) {
      this.username = name;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },
    async getChats() {
      try {
        const response = await axios.post(
          `${this.$store?.state?.auth?.supportUrl}support/${this.$route.params?.id}`,
          { id: this.$route.params?.id }
        );
        if (response?.data?.status) {
          this.support = response?.data?.data;
          console.log(this.support);
        }
      } catch (err) {
        console.error(err);
      }
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      //   this.submitted = true;

      // stop here if form is invalid
      //   this.v$.$touch();

      //   if () {
      console.log("invalid");
      // return;
      //   } else {
      let data = {};
      const user = JSON.parse(localStorage?.currentUser);
      data.sender = user?.id;
      data.receiver = this.senderUser?.id;
      data.support_request_id = this.$route?.params?.id;
      data.message = this.form.message;
      data.message_type_id = 1;
      if (this.file) {
        data.message_type_id = 2;
        data.message = "";
        return this.sendMedia(data);
      }
      if (this.location) {
        data.message_type_id = 3;
        data.message = "";
        data.lat = this.markerOptions?.position?.lat;
        data.lon = this.markerOptions?.position?.lng;
        console.log(this.markerOptions);
      }
      if (this.doc) {
        data.message_type_id = 4;
        data.doc = this.doc;
        data.message = "";
        return this.sendMedia(data);
      }
      if (data.message_type_id == 1 && data.message == "") return;
      // if (!data.message || data.message == "" || !data.location) return;
      console.log(data);
      this.socket.emit("send_message", data);
      this.location = false;
      this.handleScroll();
      this.submitted = false;
      this.form = {};
    },

    handleScroll() {
      if (this.$refs.current.$el) {
        console.log("193", this.$refs.current?.value);
        setTimeout(() => {
          this.$refs.current.value.getScrollElement().scrollTop =
            this?.$refs?.current?.value?.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },

    setLocation(e) {
      this.markerOptions = {
        position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      };
    },

    clickAddFilesBtn() {
      document.querySelector("#allFiles").click();
    },
    clickAddDocsBtn() {
      document.querySelector("#allDocs").click();
    },
    selectedDoc(event) {
      this.doc = event.target.files[0];
      let p = document.createElement("p");
      if (document.querySelector(".doc-prev").hasChildNodes()) {
        document.querySelector("#prev-doc-el").remove();
      }
      p.id = "prev-doc-el";
      p.style.padding = "1rem";
      p.style.background = "#cccc";
      p.style.borderRadius = "11%";
      p.style.border = "1px solid #CCCC";
      p.style.width = "150px";
      p.style.height = "150px";
      p.innerText = this.doc?.name;
      document.querySelector(".doc-prev").appendChild(p);
      this.handleScroll();
    },
    selectedFile(event) {
      this.file = event.target.files[0];
      const url = URL.createObjectURL(this.file);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector(".img-prev").appendChild(img);
      this.handleScroll();
    },
    deleteFile() {
      this.file = null;
      this.doc = null;
      this.location = false;
      document.querySelector("#allFiles").value = "";
      document.querySelector("#allDocs").value = "";
      if (document?.querySelector("#prev-img-el")) {
        document?.querySelector("#prev-img-el")?.remove();
      }
      if (document?.querySelector("#prev-doc-el")) {
        document?.querySelector("#prev-doc-el")?.remove();
      }
    },
    responseAlert(text, title, icon) {
      // this.$swal.fire({
      //   title: `${title}`,
      //   text: ` ${text} `,
      //   icon: `${icon}`,
      //   toast: true,
      //   position: "top-start",
      //   showConfirmButton: false,
      //   timer: 2000,
      // });
      console.log(text, title, icon);
    },
  },
  created() {
    this.getMessage();
    if (localStorage.currentUser) {
      this.currentUser = JSON.parse(localStorage.currentUser);
    }
    // this.getChats();
    // this.getLocationImage();
  },
  mounted() {
    let self = this;
    this.socket.on("message_sent", async () => {
      this.getMessage();
      this.markerOptions = {
        position: { lat: 24.774265, lng: 46.738586 },
        gmpDraggable: true,
      };
      this.center = { lat: 24.774265, lng: 46.738586 };
    });
    this.socket.on("read_success", (data) => {
      data.forEach((item) => {
        self.messages.forEach((message) => {
          if (item == message?.id) {
            message.is_read = true;
          }
        });
      });
    });
    this.socket.on("message_not_sent", async () => {
      if (this.$i18n.locale == "ar") {
        this.responseAlert(
          "حدث خطأ اثناء الإرسال الرجاء المحاولة لاحقا",
          "خطأ",
          "warning"
        );
      } else {
        this.responseAlert(
          "something went wrong, please try again later",
          "error",
          "warning"
        );
      }
    });

    var container2 = document.querySelector(
      "#containerElement .simplebar-content-wrapper"
    );
    container2.scrollTo({ top: 500, behavior: "smooth" });
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/support-requests`">
            <h4 class="m-0">
              {{ $t("menu.menuitems.support_requests.text") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>#{{ $route.params?.id }}</div>
        </div>
      </div>
    </div>

    <div class="d-lg-flex">
      <div class="w-100 user-chat">
        <div class="card">
          <div class="p-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-9">
                <h5 class="font-size-15 mb-1">
                  {{ senderUser?.name }}
                </h5>
                <!-- <p class="text-muted mb-0">
                  <i class="mdi mdi-circle text-success align-middle me-1"></i>
                  Active now
                </p> -->
              </div>
            </div>
          </div>

          <div class="chat-users">
            <div class="chat-conversation p-3">
              <SimpleBar
                style="max-height: 470px"
                id="containerElement"
                ref="current"
              >
                <ul class="list-unstyled">
                  <li
                    v-for="data of messages"
                    :key="data.message"
                    :class="{ right: data.sender == currentUser?.id }"
                  >
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <div class="conversation-name">
                          {{ data?.sender_user?.name }}
                        </div>
                        <p v-if="data?.message_type_id == 1">
                          {{ data?.message }}
                        </p>
                        <p v-if="data?.message_type_id == 2">
                          <img
                            style="width: 150px; height: 150px"
                            :src="
                              $store.state?.auth?.mediaUrl +
                              data?.message?.split('public')[1]
                            "
                            loading="lazy"
                            alt=""
                          />
                        </p>
                        <p v-if="data?.message_type_id == 3">
                          <img loading="lazy" :src="data?.imageUrl" alt="" />
                        </p>
                        <div v-if="data?.message_type_id == 4">
                          <h6 class="">
                            {{ data?.message?.split("public")[1] }}
                          </h6>
                          <a
                            :href="
                              $store.state?.auth?.mediaUrl +
                              data?.message?.split('public')[1]
                            "
                            >{{ $t("popups.download") }}</a
                          >
                        </div>
                        <p class="chat-time mb-0">
                          <i
                            v-if="!data.is_read"
                            class="bx bx-check align-middle me-1"
                          ></i>
                          <i
                            v-if="data.is_read"
                            class="bx bx-check-circle text-primary align-middle me-1"
                          ></i>
                          {{ data?.created?.split("T")[1]?.split(".")[0] }}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="img-prev"></div>
                    <div class="doc-prev"></div>
                    <div class="relative" v-if="location">
                      <div id="map">
                        <GoogleMap
                          api-key="AIzaSyChzOQofZVtnlA_g-ekWp5CLnUt2m7yUrw"
                          style="width: 100%; height: 500px"
                          :center="center"
                          :zoom="16"
                          @click="setLocation($event)"
                        >
                          <Marker :options="markerOptions" />
                        </GoogleMap>
                      </div>
                    </div>
                  </li>
                </ul>
              </SimpleBar>
            </div>
            <div class="p-3 chat-input-section">
              <form @submit.prevent="formSubmit" class="row">
                <div class="col">
                  <div class="position-relative">
                    <input
                      v-if="!file && !doc && !location"
                      type="text"
                      v-model="form.message"
                      class="form-control chat-input rounded"
                      :placeholder="$t('popups.enter_msg')"
                      :class="{
                        'is-invalid': submitted && v$.form.message.$error,
                      }"
                    />
                    <div
                      v-if="submitted && v$.form.message.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.form.message.required.$message">{{
                        v$.form.message.required.$message
                      }}</span>
                    </div>
                    <div
                      class="chat-input-links"
                      v-if="!file && !doc && !location"
                    >
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <a
                            @click="clickAddFilesBtn"
                            href="javascript: void(0);"
                            v-b-tooltip.hover
                            placement="top"
                            title="Images"
                          >
                            <i class="mdi mdi-file-image-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            @click="clickAddDocsBtn"
                            role="button"
                            href="javascript: void(0);"
                            v-b-tooltip.hover
                            placement="top"
                            title="Add File"
                          >
                            <i class="mdi mdi-file-document-outline"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            @click="location = true"
                            role="button"
                            href="javascript: void(0);"
                            v-b-tooltip.hover
                            placement="top"
                            title="Add Location"
                          >
                            <i class="mdi mdi-map-outline"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    style="margin-inline-end: 4px"
                    class="btn btn-secondary btn-rounded chat-send w-md"
                    @click="deleteFile"
                    v-if="file || doc || location"
                  >
                    <span class="d-none d-sm-inline-block me-2">{{
                      $t("popups.cancel")
                    }}</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-rounded chat-send w-md"
                  >
                    <span class="d-none d-sm-inline-block me-2"
                      >{{ $t("popups.send") }}
                    </span>
                    <i class="mdi mdi-send"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      id="allFiles"
      type="file"
      @change="selectedFile($event)"
      name=""
      value=""
      style="visibility: hidden"
    />
    <input
      id="allDocs"
      type="file"
      @change="selectedDoc($event)"
      name=""
      value=""
      style="visibility: hidden"
    />
    <!-- end row -->
  </Layout>
</template>
